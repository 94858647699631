<template>
  <div>
    <div class="questionnaire-header">
      <router-link
        :to="{ name: 'home' }"
        tag="a"
        class="flex items-center pr-2"
        :class="{ 'intro-x': hasAnimation }"
      >
        <img alt="Chexpertise" src="@/assets/images/chexpertise.svg" />
      </router-link>
    </div>

    <div class="content">
      <router-view />
    </div>

    <TheFooter />
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";
// Components
import TheFooter from "@/components/TheFooter";

export default {
  components: {
    TheFooter
  },
  setup() {
    // Composables
    const { hasAnimation } = useAnimation();

    return {
      hasAnimation
    };
  }
};
</script>
